import React, { useState } from "react"
import { Box, Button, Typography } from "@mui/material"
import Axios from "../../Util/Axios"
import { updateToastData } from "../../slices/toastSlice"
import { TOAST_SEVERITY_ERROR } from "../../components/core/Toast/Toast"
import { useDispatch } from "react-redux"
import Tooltip from "@mui/material/Tooltip"

const CustomOrderButton = ({
  orderData,
  setOrderData,
  requiresApproval,
  customerFacingId,
}) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  let tooltipContent = ""
  if (
    ["deposit_received_and_in_progress", "awaiting_confirmation"].includes(
      orderData?.status
    )
  ) {
    return <React.Fragment />
  }
  let buttonText = ""
  let color = ""

  let onClick = () => {}

  let missingAddress =
    !orderData?.address.email ||
    !orderData?.address.first_name ||
    !orderData?.address.phone
  let disabled =
    !orderData || loading || missingAddress || orderData?.balance <= 0

  if (orderData?.status === "ready_for_pickup") {
    buttonText = "Pay Balance"
    color = "primary"
    onClick = () => {
      setLoading(true)
      Axios.post(
        `/api/order-detail/${customerFacingId}/action_create_order_balance_payment_session/`
      )
        .then(({ data }) => {
          window.open(data.url, "_self")
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  } else if (
    orderData?.status === "confirmed" ||
    !requiresApproval ||
    orderData?.locked
  ) {
    buttonText = "Pay Deposit"
    color = "primary"
    onClick = () => {
      setLoading(true)
      Axios.post(
        `/api/order-detail/${customerFacingId}/action_create_order_deposit_payment_session/`
      )
        .then(({ data }) => {
          window.open(data.url, "_self")
        })
        .catch((e) => {
          setLoading(false)
        })
    }
  } else {
    buttonText = "Submit for Approval"
    color = "secondary"

    if (missingAddress) {
      tooltipContent = "Missing contact info"
    }

    if (
      !orderData?.comment?.text &&
      !orderData?.comment?.message_attachments.length
    ) {
      disabled = true
      if (!tooltipContent) {
        tooltipContent = "Please add a comment before submitting for approval"
      }
    }

    onClick = () => {
      setLoading(true)
      Axios.post(
        `/api/order-detail/${customerFacingId}/action_submit_for_approval/`
      )
        .then(({ data }) => {
          setLoading(false)
          setOrderData(data)
          document.documentElement.scrollTop = 0
          document.scrollingElement.scrollTop = 0
        })
        .catch(() => {
          setLoading(false)
          dispatch(
            updateToastData({
              message: "Failed to submit for approval",
              severity: TOAST_SEVERITY_ERROR,
            })
          )
        })
    }
  }

  return (
    <Tooltip title={tooltipContent}>
      <span>
        <Button
          variant="contained"
          color={color}
          disabled={disabled}
          fullWidth
          // sx={{ mt: 1, width: "100%" }}
          onClick={onClick}
        >
          <Typography
            variant="h6"
            style={{ color: "white", fontFamily: "Josefin Sans" }}
          >
            {buttonText}
          </Typography>
        </Button>
      </span>
    </Tooltip>
  )
}
export default CustomOrderButton
