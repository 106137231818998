import React from "react"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"

const Modal = ({
  active,
  setActive,
  title,
  children,
  onConfirm,
  confirmDisabled,
  confirmButtonName,
  width = 500,
  maxHeight = 600,
  backgroundColor,
}) => {
  const StyledPaper = styled(Paper)({
    width: width, // Your desired width
    maxHeight: maxHeight, // Your desired height
  })
  return (
    <Dialog
      open={active}
      onClose={() => {
        setActive(false)
      }}
      PaperComponent={StyledPaper}
    >
      <DialogTitle
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: backgroundColor,
        }}
      >
        {children}
      </DialogContent>
      {confirmButtonName && (
        <DialogActions>
          <Button
            variant="contained"
            color={"secondary"}
            sx={{ mt: 1, width: "100%" }}
            onClick={() => {
              setActive(false)
            }}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              Close
            </Typography>
          </Button>
          <Button
            disabled={confirmDisabled}
            variant="contained"
            color={"primary"}
            sx={{ mt: 1, width: "100%" }}
            onClick={onConfirm}
          >
            <Typography variant="h6" style={{ color: "white" }}>
              {confirmButtonName}
            </Typography>
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export default Modal
