/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Grid from "@mui/material/Grid"

// Soft UI Dashboard React components
import SoftBox from "soft_dashboard/components/SoftBox"

// Soft UI Dashboard React examples
import DashboardLayout from "soft_dashboard/examples/LayoutContainers/DashboardLayout"
import DashboardNavbar from "soft_dashboard/examples/Navbars/DashboardNavbar"
import ReportsBarChart from "soft_dashboard/examples/Charts/BarCharts/ReportsBarChart"

// Soft UI Dashboard React base styles
import typography from "soft_dashboard/assets/theme/base/typography"

// Dashboard layout components
import OrdersOverview from "./OrdersOverview/OrdersOverview"

// Data
import reportsBarChartData from "soft_dashboard/layouts/dashboard/data/reportsBarChartData"
import React, { useEffect, useState } from "react"
import Axios from "../../Util/Axios"
import Toast, { TOAST_SEVERITY_ERROR } from "../core/Toast/Toast"
import Footer from "../core/Footer/Footer"
import MiniStatsCard from "./MiniStatsCard"
import GradientLineChart from "../core/GradientLineChart/GradientLineChart"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../slices/toastSlice"
import { getCurrencyStringFromNumber } from "../../Util/Util"

const Dashboard = () => {
  const { size } = typography
  const { chart, items } = reportsBarChartData

  const [statsData, setStatsData] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffect(() => {
    Axios.get("/api/stats/", navigate)
      .then(({ data }) => {
        setStatsData(data)
      })
      .catch((e) => {
        dispatch(
          updateToastData({
            message: e,
            severity: TOAST_SEVERITY_ERROR,
          })
        )
      })
  }, [])

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatsCard
                title={"Total Revenue"}
                // bgColor={"primary"}
                count={getCurrencyStringFromNumber(statsData?.total_revenue)}
                percentage={null}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatsCard
                title={"Monthly Revenue"}
                count={getCurrencyStringFromNumber(statsData?.monthly_revenue)}
                percentage={statsData?.monthly_revenue_percentage_change}
                icon={{ color: "info", component: "paid" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatsCard
                title={"Total Orders"}
                count={statsData?.total_orders}
                icon={{ color: "info", component: "inventory2" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <MiniStatsCard
                title={"Monthly Orders"}
                count={statsData?.monthly_orders}
                percentage={statsData?.monthly_order_percentage_change}
                icon={{ color: "info", component: "inventory2" }}
              />
            </Grid>
          </Grid>
        </SoftBox>
        {/*<SoftBox mb={3}>*/}
        {/*  <Grid container spacing={3}>*/}
        {/*    <Grid item xs={12} lg={7}>*/}
        {/*      <BuildByDevelopers />*/}
        {/*    </Grid>*/}
        {/*    <Grid item xs={12} lg={5}>*/}
        {/*      <WorkWithTheRockets />*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</SoftBox>*/}
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            {/*<Grid item xs={12} lg={5}>*/}

            {/*</Grid>*/}
            <Grid item xs={8} lg={8}>
              <GradientLineChart
                title={`Sales Overview`}
                height="20.25rem"
                chart={statsData?.line_chart_data}
              />
            </Grid>
            <Grid item xs={4} lg={4}>
              <OrdersOverview statsData={statsData} />
            </Grid>
          </Grid>
        </SoftBox>
        <Grid container spacing={3}>
          {/*<Grid item xs={12} md={6} lg={8}>*/}
          {/*  <ReportsBarChart*/}
          {/*    title="active users"*/}
          {/*    description={*/}
          {/*      <>*/}
          {/*        (<strong>+23%</strong>) than last week*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    chart={chart}*/}
          {/*    items={items}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={12} md={6} lg={8}>*/}
          {/*  <OrdersOverview statsData={statsData} />*/}
          {/*</Grid>*/}
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  )
}

export default Dashboard
