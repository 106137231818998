import * as React from "react"

const MegaFishSvg = ({
  extendedProps,
  originalHeightInches,
  originalWidthInches,
}) => {
  const {
    bottomColor,
    stringerColor,
    selectedDeckPatchOption,
    selectedTailPatchOption,
    selectedFinBoxColorOption,
    selectedFinSetupOption,
    selectedPinLineColorOption,
    selectedNumberOfStringersOption,
    deck,
    getHeightScaleFactor,
    getWidthScaleFactor,
    backgroundColor,
    tailPatchOpacity,
    backgroundOpacity,
    deckPatchColor,
    deckPatchOpacity,
    tailPatchColor,
    secondaryColor,
    overlapOpacity,
    logoUrl,
    outlineOpacity,
  } = extendedProps

  let heightScale = getHeightScaleFactor(originalHeightInches)
  let widthScale = getWidthScaleFactor(originalWidthInches)
  // let widthScale = 1
  let originalWidth = 305
  let originalHeight = 922

  let width = originalWidth * widthScale
  let height = originalHeight * heightScale

  return (
    <svg
      ref={extendedProps.svgRef}
      style={extendedProps.style}
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={originalWidth}
      height={originalHeight}
      viewBox={`0 0 ${width} ${height}`}
      xmlSpace="preserve"
    >
      <g
        id={"Background"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill={backgroundColor}
        opacity={backgroundOpacity}
        stroke="none"
      >
        >
        <path
          id="_x3C_Path_x3E__14_"
          d="M153.243,811.492
		c0,0,0,11.656-18.8,45.156c-18.8,33.499-63.74,37.303-63.74,37.303S47.964,766.335,45.707,408.027
		C43.451,49.718,153.243,14.379,153.243,14.379s109.794,35.339,107.538,393.648c-2.258,358.308-24.871,485.924-24.871,485.924
		s-45.065-3.804-63.866-37.303C153.243,823.148,153.243,811.492,153.243,811.492z"
        />
      </g>
      <g
        id={"Overlap"}
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        strokeMiterlimit={10}
        strokeWidth={18}
        opacity={overlapOpacity}
      >
        <path
          id="_x3C_Path_x3E__6_"
          d="
		M80.574,891.329c0,0-21.587-131.852-23.606-481.006c-2.02-349.149,96.277-383.585,96.277-383.585s98.301,34.437,96.279,383.585
		c-2.021,349.151-23.484,481.006-23.484,481.006"
        />
      </g>
      {deck && selectedDeckPatchOption?.value === "Straight Point" && (
        <g
          id="DeckPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            id="_x3C_Path_x3E__12_"
            d="M260.782,408.027
                c-2.258,358.308-24.871,485.924-24.871,485.924s-45.064-3.804-63.866-37.303c-18.801-33.5-18.801-45.156-18.801-45.156
                s0,11.656-18.8,45.156c-18.8,33.499-63.74,37.303-63.74,37.303S47.965,766.335,45.708,408.027l107.647-130.971L260.782,408.027z"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Round" && (
        <g
          id="DeckPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={deckPatchOpacity}
          fill={deckPatchColor}
        >
          <path
            id="_x3C_Path_x3E__11_"
            d="M260.782,408.027
                c-2.258,358.308-24.871,485.924-24.871,485.924s-45.064-3.804-63.866-37.303c-18.801-33.5-18.801-45.156-18.801-45.156
                s0,11.656-18.8,45.156c-18.8,33.499-63.74,37.303-63.74,37.303S47.965,766.335,45.708,408.027
                C45.707,408.027,141.71,301.426,260.782,408.027z"
          />
        </g>
      )}
      {deck && selectedDeckPatchOption?.value === "Curved Point" && (
        <g
          id="DeckPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={deckPatchColor}
          opacity={deckPatchOpacity}
        >
          <path
            id="_x3C_Path_x3E__10_"
            d="M260.782,408.027
                c-2.258,358.308-24.871,485.924-24.871,485.924s-45.064-3.804-63.866-37.303c-18.801-33.5-18.801-45.156-18.801-45.156
                s0,11.656-18.8,45.156c-18.8,33.499-63.74,37.303-63.74,37.303S47.965,766.335,45.708,408.027c0,0,51.042-2.044,79.345-53.091
                c28.304-51.047,28.075-103.402,28.075-103.402s0.573,76.636,29.577,109.292C222.814,405.982,260.782,408.027,260.782,408.027z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Straight Point" && (
        <g
          id="TailPatchStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="_x3C_Path_x3E__9_"
            d="M152.732,616.904l101.616,47.583
                c0,0-3.202,76.354-4.795,92.561c-2.466,54.748-14.133,136.903-14.133,136.903s-43.058-3.804-62.617-37.303
                c-19.561-33.5-19.561-45.156-19.561-45.156s0,11.656-19.56,45.156c-19.561,33.499-62.931,37.303-62.931,37.303
                s-7.333-39.487-13.878-138.522c-2.092-30.318-1.555-54.379-4.396-87.023L152.732,616.904z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Straight Point" && (
        <g
          id="TailPatchReverseStraightPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="_x3C_Path_x3E__8_"
            d="M152.732,708.251l103.51-67.248
                c0,0-4.243,88.056-6.784,117.464c-6.701,96.997-13.701,135.484-13.701,135.484s-43.392-3.804-62.95-37.303
                c-19.562-33.5-19.562-45.156-19.562-45.156s0,11.656-19.56,45.156c-19.561,33.499-62.598,37.303-62.598,37.303
                s-8-38.487-14.152-137.657c-2.49-32.455-3.843-82.646-6.687-115.291L152.732,708.251z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Curved Point" && (
        <g
          id="TailPatchCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={tailPatchColor}
          opacity={tailPatchOpacity}
        >
          <path
            id="_x3C_Path_x3E__7_"
            d="M254.349,664.487
                c0,0-2.567,66.987-4.893,93.979c-6.035,96.663-13.478,135.092-13.478,135.092s-43.615-3.408-63.177-36.907
                c-19.561-33.5-19.561-45.156-19.561-45.156s0,11.656-19.56,45.156c-19.561,33.499-64.264,37.303-64.264,37.303
                s-5.667-39.488-12.611-139.516c0.126-25.309-2.613-52.659-4.33-86.032c0,0,34.4,1.328,59.178-16.341
                c24.776-17.671,41.589-44.176,41.589-44.176s19.08,28.322,37.557,40.573C214.075,663.9,254.349,664.487,254.349,664.487z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Reverse Curved Point" && (
        <g
          id="TailPatchReverseCurvedPoint"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="_x3C_Path_x3E__5_"
            d="M256.241,641.003
                c0,0-4.343,90.818-6.688,117.46c-5,96.333-13.466,135.488-13.466,135.488s-43.725-3.804-63.284-37.303
                c-19.561-33.5-19.561-45.156-19.561-45.156s0,11.656-19.56,45.156c-19.561,33.499-62.598,37.303-62.598,37.303
                s-8.333-40.155-14.152-139.822c-1.515-28-3.843-80.479-6.687-113.126c0,0,43.412-3.328,66.623,19.576
                c23.211,22.907,36.543,56.938,36.543,56.938s17.468-45.702,38.826-59.555C215.452,642.91,243.825,639.751,256.241,641.003z"
          />
        </g>
      )}
      {!deck && selectedTailPatchOption?.value === "Round" && (
        <g
          id="TailPatchRound"
          transform={`scale(${widthScale}, ${heightScale})`}
          opacity={tailPatchOpacity}
          fill={tailPatchColor}
        >
          <path
            id="_x3C_Path_x3E__4_"
            d="M254.349,664.487
                c0,0-2.469,66.648-4.795,93.976c-5.466,96-13.573,135.096-13.573,135.096s-43.615-3.409-63.177-36.908
                c-19.561-33.5-19.561-45.156-19.561-45.156s0,11.656-19.56,45.156c-19.561,33.499-63.05,36.906-63.05,36.906
                s-7.547-39.094-13.771-138.324c-1.972-26.784-1.541-54.184-4.383-86.827C52.479,668.403,142.242,555.189,254.349,664.487z"
          />
        </g>
      )}
      {["Triple", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerTriple"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerTripleRight_1_"
            x1="228.21"
            y1="125.405"
            x2="228.21"
            y2="889.668"
          />

          <line
            id="StringerTripleLeft"
            x1="78.498"
            y1="125.405"
            x2="78.498"
            y2="889.668"
          />
        </g>
      )}
      {["Double", "Five"].includes(selectedNumberOfStringersOption?.value) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerDouble"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerDoubleLeft"
            x1="142.014"
            y1="19.621"
            x2="142.014"
            y2="841.762"
          />

          <line
            id="StringerDoubleRight_1_"
            x1="164.698"
            y1="19.621"
            x2="164.698"
            y2="841.762"
          />
        </g>
      )}
      {["Single", "Triple", "Five"].includes(
        selectedNumberOfStringersOption?.value
      ) && (
        <g
          transform={`scale(${widthScale}, ${heightScale})`}
          id="StringerSingle"
          stroke={stringerColor}
          strokeWidth="4"
          strokeMiterlimit="10"
        >
          <line
            id="StringerSingle"
            x1="153.355"
            y1="14.379"
            x2="153.355"
            y2="820.596"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Twin" && (
        <g
          id="FinSetupTwin"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
        >
          <path
            fill="#FFFFFF"
            d="M83.515,788.705c-0.071,2.335-2.008,4.174-4.324,4.106
                l-5.911-0.176c-2.317-0.068-4.139-2.014-4.067-4.351l1.462-47.969c0.07-2.333,2.006-4.169,4.323-4.1l5.912,0.173
                c2.318,0.068,4.141,2.012,4.068,4.349L83.515,788.705z"
          />
          <path
            fill="#FFFFFF"
            d="M236.822,788.291c0.069,2.333-1.751,4.279-4.064,4.347
                l-5.914,0.173c-2.314,0.065-4.254-1.771-4.324-4.105l-1.468-47.969c-0.07-2.332,1.746-4.279,4.063-4.35l5.914-0.173
                c2.313-0.063,4.254,1.771,4.327,4.108L236.822,788.291z"
          />
        </g>
      )}
      {!deck && selectedFinSetupOption?.value === "Quad" && (
        <g
          id="FinSetupQuad"
          transform={`scale(${widthScale}, ${heightScale})`}
          fill={selectedFinBoxColorOption?.value}
          stroke={"black"}
          strokeMiterlimit={10}
        >
          <path
            fill="#FFFFFF"
            d="M83.033,774.044c-0.063,2.369-1.76,4.235-3.79,4.17l-5.182-0.182
                c-2.032-0.071-3.627-2.045-3.565-4.418l1.282-48.728c0.062-2.369,1.757-4.233,3.789-4.162l5.183,0.173
                c2.03,0.071,3.629,2.047,3.565,4.421L83.033,774.044z"
          />
          <path
            fill="#FFFFFF"
            d="M98.625,835.869c0,2.371-1.784,4.291-3.986,4.291H89.02
                c-2.204,0-3.988-1.92-3.988-4.291v-48.743c0-2.372,1.785-4.294,3.988-4.294h5.619c2.202,0,3.986,1.925,3.986,4.294V835.869z"
          />
          <path
            fill="#FFFFFF"
            d="M235.979,773.619c0.062,2.369-1.533,4.349-3.563,4.417
                l-5.184,0.175c-2.031,0.067-3.729-1.799-3.79-4.169l-1.285-48.729c-0.06-2.37,1.531-4.35,3.563-4.416l5.187-0.174
                c2.031-0.064,3.729,1.799,3.791,4.169L235.979,773.619z"
          />
          <path
            fill="#FFFFFF"
            d="M222.155,835.869c0,2.371-1.787,4.291-3.988,4.291h-5.622
                c-2.197,0-3.984-1.92-3.984-4.291v-48.743c0-2.372,1.787-4.294,3.984-4.294h5.622c2.201,0,3.988,1.925,3.988,4.294V835.869z"
          />
        </g>
      )}
      <g id="LogoBox" transform={`scale(${widthScale}, ${heightScale})`}>
        <image
          href={logoUrl}
          x="109.312"
          y="593.038"
          width="87.866"
          height="96"
        />
      </g>
      {deck && selectedPinLineColorOption && (
        <g
          id={"PinLine"}
          transform={`scale(${widthScale}, ${heightScale})`}
          fill="none"
          stroke={selectedPinLineColorOption.value}
          strokeMiterlimit={10}
          strokeWidth={2}
        >
          <path
            id="_x3C_Path_x3E__1_"
            d="M86.294,887.411
                c0,0-21.853-125.988-23.757-469.988C60.635,73.428,153.244,39.5,153.244,39.5s92.61,33.929,90.708,377.923
                c-1.903,343.997-23.443,469.996-23.443,469.996"
          />
        </g>
      )}
      <g
        id="TailLine"
        transform={`scale(${widthScale}, ${heightScale})`}
        fill="none"
        stroke={secondaryColor}
        opacity={0.2}
        strokeMiterlimit={10}
        strokeWidth={3}
      >
        <path
          id="_x3C_Path_x3E__13_"
          d="M222.355,888.36
                c0,0-32.629-4.302-49.337-30.19c-12.36-19.152-19.773-46.68-19.773-46.68s-3.502,19.132-18.8,45.156
                c-16.04,27.282-50.718,32.021-50.718,32.021"
        />
      </g>
      <g
        transform={`scale(${widthScale}, ${heightScale})`}
        id="Outline"
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="3"
        opacity={outlineOpacity}
      >
        <path
          id="_x3C_Path_x3E__3_"
          d="M153.243,811.492
                c0,0,0,11.656-18.8,45.156c-18.8,33.499-63.74,37.303-63.74,37.303S47.964,766.335,45.707,408.027
                C43.451,49.718,153.243,14.379,153.243,14.379s109.794,35.339,107.538,393.648c-2.258,358.308-24.871,485.924-24.871,485.924
                s-45.065-3.804-63.866-37.303C153.243,823.148,153.243,811.492,153.243,811.492z"
        />
      </g>
    </svg>
  )
}

export default MegaFishSvg
