import React, { useEffect, useState } from "react"
import { InstagramGallery } from "instagram-gallery"
import "./InstagramGalleryWrapper.css"
const InstagramGalleryWrapper = () => {
  return (
    <InstagramGallery
      accessToken="IGQWRNWXNtNldINW9GTUMwSzhiNVhCSGNZANklhSDBmaVR1QkNVOFBtbnpEUkNmeEoyWHJUODdtYTdBWU01clF2cWZAyeVZA2RGhlSkFhTXVuandXQzh3Ul9uQ3NxTnU2T25mUDhpR1NiOUtQQUZAxbE52bWtxLVVsX2MZD"
      count={24}
      pagination={false}
      // pagination={true}
    />
  )
}

export default InstagramGalleryWrapper
