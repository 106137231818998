// @mui material components
import Icon from "@mui/material/Icon"

// Soft UI Dashboard React components
import SoftBox from "soft_dashboard/components/SoftBox"
import SoftTypography from "soft_dashboard/components/SoftTypography"
import SoftBadge from "soft_dashboard/components/SoftBadge"

// Timeline context
import { ordersOverviewItemIcon, ordersOverviewItemStyle } from "./styles"
import { getCurrencyStringFromNumber } from "../../../Util/Util"

const OrdersOverviewItem = ({
  color = "info",
  icon,
  title,
  thisMonthsOrders,
  thisMonthRevenue,
  totalOrders,
  badges = [],
  lastItem = false,
}) => {
  const renderBadges =
    badges.length > 0
      ? badges.map((badge, key) => {
          const badgeKey = `badge-${key}`

          return (
            <SoftBox key={badgeKey} mr={key === badges.length - 1 ? 0 : 0.5}>
              <SoftBadge
                color={color}
                size="xs"
                badgeContent={badge}
                container
              />
            </SoftBox>
          )
        })
      : null

  return (
    <SoftBox position="relative" sx={(theme) => ordersOverviewItemStyle(theme)}>
      <SoftBox
        bgColor={"white"}
        width="1.625rem"
        height="1.625rem"
        borderRadius="50%"
        position="absolute"
        top="3.25%"
        left="2px"
        zIndex={2}
      >
        <Icon sx={(theme) => ordersOverviewItemIcon(theme, { color })}>
          {icon}
        </Icon>
      </SoftBox>
      <SoftBox ml={5.75} pt={0.7} lineHeight={0} maxWidth="30rem">
        <SoftTypography variant="button" fontWeight="medium" color={"dark"}>
          {title}
        </SoftTypography>
        <SoftBox mt={3} mb={1.75}>
          <SoftTypography variant="caption" fontWeight="medium" color="text">
            Monthly: {thisMonthsOrders}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={3} mb={1.75}>
          <SoftTypography variant="caption" fontWeight="medium" color="text">
            Monthly Revenue: {getCurrencyStringFromNumber(thisMonthRevenue)}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={3} mb={1.75}>
          <SoftTypography variant="caption" fontWeight="medium" color="text">
            All Time: {totalOrders}
          </SoftTypography>
        </SoftBox>
        {badges.length > 0 && (
          <SoftBox display="flex" pb={lastItem ? 1 : 2}>
            {renderBadges}
          </SoftBox>
        )}
      </SoftBox>
    </SoftBox>
  )
}

export default OrdersOverviewItem
