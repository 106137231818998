import React, { useEffect, useState } from "react"
import TextField from "@mui/material/TextField"
import { useSearchParams } from "react-router-dom"
import DataTableSearchBarFilter from "./DataTableSearchBarFilters"
import { Box } from "@mui/material"

const DataTableSearchBar = ({ getData, filters = [] }) => {
  const [searchTerm, setSearchTerm] = useState("")
  let [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    setSearchTerm(searchParams.get("search") || "")
  }, [])

  return (
    <Box p={2}>
      <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          fullWidth
          value={searchTerm}
          onChange={(event) => {
            setSearchTerm(event.target.value)
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              event.preventDefault()
              searchParams.set("search", searchTerm)
              setSearchParams(searchParams)
              getData(1, searchTerm)
            }
          }}
        />
        {filters.map((filter) => {
          return (
            <DataTableSearchBarFilter
              filter={filter}
              getData={getData}
              searchTerm={searchTerm}
            />
          )
        })}
      </div>
    </Box>
  )
}

export default DataTableSearchBar
