/**
 =========================================================
 * Soft UI Dashboard React - v4.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React, { useEffect, useRef, useState } from "react"

// porp-types is a library for typechecking of props
import PropTypes from "prop-types"

// react-chartjs-2 components
import { Line } from "react-chartjs-2"

// @mui material components
import Card from "@mui/material/Card"

// Soft UI Dashboard React components
import SoftBox from "soft_dashboard/components/SoftBox"
import SoftTypography from "soft_dashboard/components/SoftTypography"

// Soft UI Dashboard React helper functions
import gradientChartLine from "soft_dashboard/assets/theme/functions/gradientChartLine"

// GradientLineChart configurations
import configs from "soft_dashboard/examples/Charts/LineCharts/GradientLineChart/configs"

// Soft UI Dashboard React base styles
import colors from "soft_dashboard/assets/theme/base/colors"
import SkeletonCard from "../SkeletonCard/SkeletonCard"

const GradientLineChart = ({
  title = "",
  description = "",
  height = "19.125rem",
  chart,
}) => {
  const chartRef = useRef(null)
  const [chartData, setChartData] = useState({})
  const { data, options } = chartData

  useEffect(() => {
    if (!chart) {
      return
    }
    const chartDatasets = chart.datasets
      ? chart.datasets.map((dataset) => ({
          ...dataset,
          tension: 0.4,
          pointRadius: 0,
          borderWidth: 3,
          borderColor: colors[dataset.color]
            ? colors[dataset.color || "dark"].main
            : colors.dark.main,
          fill: true,
          maxBarThickness: 6,
          backgroundColor: gradientChartLine(
            chartRef.current.children[0],
            colors[dataset.color]
              ? colors[dataset.color || "dark"].main
              : colors.dark.main
          ),
        }))
      : []

    setChartData(configs(chart.labels || [], chartDatasets))
  }, [chart])

  if (!chart) {
    return (
      <SoftBox ref={chartRef} sx={{ height }}>
        <SkeletonCard />
      </SoftBox>
    )
  }

  const renderChart = (
    <SoftBox p={2}>
      {title || description ? (
        <SoftBox px={description ? 1 : 0} pt={description ? 1 : 0}>
          {title && (
            <SoftBox mb={1}>
              <SoftTypography variant="h6">{title}</SoftTypography>
            </SoftBox>
          )}
          <SoftBox mb={2}>
            <SoftTypography
              component="div"
              variant="button"
              fontWeight="regular"
              color="text"
            >
              {description}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      <SoftBox ref={chartRef} sx={{ height }}>
        <Line data={data} options={options} />
      </SoftBox>
    </SoftBox>
  )

  return title || description ? <Card>{renderChart}</Card> : renderChart
}

export default GradientLineChart
