import React, { useEffect, useState } from "react"

import { Navigate, Route, Routes } from "react-router-dom"
import StaffComponent from "../components/StaffRouteComponent/StaffRouteComponent"

import SignInPage from "../SignIn/SignIn"
import StaffOrderList from "../components/Order/StaffOrderList/StaffOrderList"
import Home from "../MarlinSurf/Home/Home"
import Profile from "../soft_dashboard/layouts/profile"
import StaffOrderPage from "../components/Order/StaffOrderPage/StaffOrderPage"
import StaffProductList from "../components/Product/StaffProductList/StaffProductList"
import StaffProductPage from "../components/Product/StaffProductPage/StaffProductPage"
import Dashboard from "../components/Dashboard/Dashboard"
import staffTheme from "../soft_dashboard/assets/theme"
import { ThemeProvider } from "@mui/material/styles"
import { userTheme } from "../assets/userTheme"
import About from "../MarlinSurf/About/About"
import Gallery from "../MarlinSurf/Gallery/Gallery"
import ApparelList from "../MarlinSurf/Apparel/ApparelList"
import Faq from "../MarlinSurf/Faq/Faq"
import StaffGalleryPage from "../components/Gallery/StaffGalleryPage/StaffGalleryPage"
import InventoryList from "../MarlinSurf/Inventory/InventoryList"
import ApparelPage from "../MarlinSurf/Apparel/ApparelPage"
import InventoryPage from "../MarlinSurf/Inventory/InventoryPage"
import CartPage from "../MarlinSurf/Cart/CartPage"
import CheckoutStatus from "../MarlinSurf/CheckoutStatus/CheckoutStatus"
import CustomOrder from "../MarlinSurf/CustomOrder/CustomOrder"
import PrivacyPolicy from "../MarlinSurf/Policies/PrivacyPolicy"
import CookiePolicy from "../MarlinSurf/Policies/CookiePolicy"
import ContactPage from "../MarlinSurf/Contact/ContactPage"
import PageNotFound from "../components/core/PageNotFound/PageNotFound"
import StaffCartPage from "../components/Cart/StaffCartPage/StaffCartPage"
import StaffCartList from "../components/Cart/StaffCartList/StaffCartList"
import StaffOptionPage from "../Staff/StaffOptionPage/StaffOptionPage"
import StaffPresetList from "../Staff/StaffPresetList/StaffPresetList"

const ProtectedRoute = ({ children, isAuthenticated }) => {
  if (isAuthenticated === false) {
    return <Navigate to="/signin" replace />
  }
  return <ThemeProvider theme={staffTheme}> {children} </ThemeProvider>
}
const UserRoute = ({ children }) => {
  return <ThemeProvider theme={userTheme}> {children} </ThemeProvider>
}
const StaffUserRoute = ({ children, isAuthenticated }) => {
  if (isAuthenticated === false) {
    return <Navigate to="/signin" replace />
  }
  return <ThemeProvider theme={userTheme}> {children} </ThemeProvider>
}

const AppRouter = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)

  useEffect(() => {
    setIsAuthenticated(!!localStorage.getItem("token"))
  }, [])

  return (
    <Routes>
      <Route
        path="/signin"
        element={
          <SignInPage
            isAuthenticated={isAuthenticated}
            setIsAuthenticated={setIsAuthenticated}
          />
        }
        key="signin"
      />
      <Route
        exact
        path={"/staff"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <Dashboard />
            </StaffComponent>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={"/staff/orders/:customer_facing_id"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffOrderPage />
            </StaffComponent>
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path={"/staff/orders"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffOrderList />
            </StaffComponent>
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path={"/staff/options"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffOptionPage />
            </StaffComponent>
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path={"/staff/presets"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffPresetList />
            </StaffComponent>
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path={"/staff/products/:id"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffProductPage />
            </StaffComponent>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={"/staff/gallery"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffGalleryPage />
            </StaffComponent>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={"/staff/products"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffProductList />
            </StaffComponent>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={"/staff/carts/:id"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffCartPage />
            </StaffComponent>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={"/staff/carts"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffCartList />
            </StaffComponent>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={"/staff/products/:id"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffProductPage />
            </StaffComponent>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={"/staff/products"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffProductList />
            </StaffComponent>
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path={"/staff/gallery"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <StaffGalleryPage />
            </StaffComponent>
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path={"/staff/settings"}
        element={
          <ProtectedRoute isAuthenticated={isAuthenticated}>
            <StaffComponent>
              <Home />
            </StaffComponent>
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path={"/gallery"}
        element={
          <UserRoute>
            <Gallery />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/about"}
        element={
          <UserRoute>
            <About />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/faq"}
        element={
          <UserRoute>
            <Faq />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/merchandise/:id"}
        element={
          <UserRoute>
            <ApparelPage />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/merchandise"}
        element={
          <UserRoute>
            <ApparelList />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/surfboard-inventory/:id"}
        element={
          <UserRoute>
            <InventoryPage />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/checkout-success"}
        element={
          <UserRoute>
            <CheckoutStatus success={true} />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/checkout-failed"}
        element={
          <UserRoute>
            <CheckoutStatus success={false} />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/surfboard-inventory"}
        element={
          <UserRoute>
            <InventoryList />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/custom-surfboard-order/:customer_facing_id"}
        element={
          <UserRoute>
            <CustomOrder />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/custom-surfboard-order"}
        element={
          <UserRoute>
            <CustomOrder />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/custom-surfboard-order-staff/:customer_facing_id"}
        element={
          <StaffUserRoute>
            <CustomOrder isStaff={true} />
          </StaffUserRoute>
        }
      />
      <Route
        exact
        path={"/custom-surfboard-order-staff"}
        element={
          <StaffUserRoute>
            <CustomOrder isStaff={true} />
          </StaffUserRoute>
        }
      />
      <Route
        exact
        path={"/custom-surfboard-order-staff-preset/:editing_preset_id"}
        element={
          <StaffUserRoute>
            <CustomOrder isStaff={true} presetEditor={true} />
          </StaffUserRoute>
        }
      />
      <Route
        exact
        path={"/custom-surfboard-order-staff-preset"}
        element={
          <StaffUserRoute>
            <CustomOrder isStaff={true} presetEditor={true} />
          </StaffUserRoute>
        }
      />
      <Route
        exact
        path={"/cart"}
        element={
          <UserRoute>
            <CartPage />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/privacy"}
        element={
          <UserRoute>
            <PrivacyPolicy />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/cookies"}
        element={
          <UserRoute>
            <CookiePolicy />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/contact"}
        element={
          <UserRoute>
            <ContactPage />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"/"}
        element={
          <UserRoute>
            <Home />
          </UserRoute>
        }
      />
      <Route
        exact
        path={"*"}
        element={
          <UserRoute>
            <PageNotFound />
          </UserRoute>
        }
      />
    </Routes>
  )
}

export default AppRouter
