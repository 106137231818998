import React, { useEffect, useState } from "react"
import SkeletonCard from "../../core/SkeletonCard/SkeletonCard"
import DescriptionItem from "../../core/DescriptionItem/DescriptionItem"
import SoftTypography from "../../../soft_dashboard/components/SoftTypography"
import Card from "@mui/material/Card"
import SoftBox from "../../../soft_dashboard/components/SoftBox"
import TextButton from "../../core/TextButton/TextButton"
import TextField from "@mui/material/TextField"
import { InputAdornment, Select } from "@mui/material"
import Grid from "@mui/material/Grid"
import Axios from "../../../Util/Axios"
import {
  TOAST_SEVERITY_ERROR,
  TOAST_SEVERITY_SUCCESS,
} from "../../core/Toast/Toast"
import { Link, useNavigate } from "react-router-dom"
import MenuItem from "@mui/material/MenuItem"
import { useDispatch } from "react-redux"
import { updateToastData } from "../../../slices/toastSlice"
import AutocompleteTextField from "../../AutocompleteTextField/AutocompleteTextField"
import Button from "@mui/material/Button"

const ProductInfo = ({ productData, setProductData }) => {
  const [loading, setLoading] = useState(false)
  const [syncing, setSyncing] = useState(false)
  const [price, setPrice] = useState(productData.price)
  const [status, setStatus] = useState("")
  const [statusChoices, setStatusChoices] = useState([])
  const [preset, setPreset] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (productData) {
      setPrice(productData.price)
      setStatus(productData.status)
    }
  }, [productData])

  const dataUrl = "/api/staff-product-detail/"
  useEffect(() => {
    Axios.get(`${dataUrl}model_choices/?fields=status`).then(({ data }) => {
      setStatusChoices(data.status.choices)
    })
  }, [])
  if (!productData) {
    return <SkeletonCard />
  }

  const isApparel = productData.type === "apparel"
  return (
    <Grid item>
      <Card sx={{ height: "100%" }}>
        <SoftBox p={2}>
          <DescriptionItem
            spacing={"normal"}
            keyJsx={
              <SoftTypography mb={1} variant="h6" fontWeight="medium">
                Info
              </SoftTypography>
            }
            valueJsx={
              <Button
                component={Link}
                // to={action.route}
                variant="text"
                size="small"
                to={`/surfboard-inventory/${productData.id}/`}
                // color={action.color}
              >
                View on Public Site
                {/*{action.label}*/}
              </Button>
            }
          />

          <SoftBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
          >
            <DescriptionItem
              keyJsx={
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  Type
                </SoftTypography>
              }
              valueJsx={
                <SoftTypography variant="button" fontWeight="bold" color="text">
                  {productData.type_display}
                </SoftTypography>
              }
            />
            {productData.external_id && (
              <DescriptionItem
                keyJsx={
                  <SoftTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                  >
                    External ID
                  </SoftTypography>
                }
                valueJsx={
                  <SoftTypography
                    variant="button"
                    fontWeight="bold"
                    color="text"
                  >
                    {productData.external_id}
                  </SoftTypography>
                }
              />
            )}
            <DescriptionItem
              keyJsx={
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                >
                  Status
                </SoftTypography>
              }
              valueJsx={
                <React.Fragment>
                  {/*{!isApparel && (*/}
                  <Select
                    disabled={loading}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={status}
                    label="Status"
                    onChange={(event) => {
                      if (
                        ["live", "sold"].includes(event.target.value) &&
                        !productData.preset
                      ) {
                        dispatch(
                          updateToastData({
                            message:
                              "Product needs a preset to be live or sold.",
                            severity: TOAST_SEVERITY_ERROR,
                          })
                        )
                        return
                      }
                      setLoading(true)
                      Axios.patch(
                        `${dataUrl}${productData.id}/`,
                        { status: event.target.value },
                        navigate
                      )
                        .then(({ data }) => {
                          setLoading(false)
                          setStatus(data.status)
                          setProductData(data)
                          dispatch(
                            updateToastData({
                              message: "Status Saved",
                              severity: TOAST_SEVERITY_SUCCESS,
                            })
                          )
                        })
                        .catch((e) => {
                          setLoading(false)
                          dispatch(
                            updateToastData({
                              message: e,
                              severity: TOAST_SEVERITY_ERROR,
                            })
                          )
                        })
                    }}
                  >
                    {statusChoices.map((choice, i) => {
                      return (
                        <MenuItem key={i} value={choice.value}>
                          {choice.key}
                        </MenuItem>
                      )
                    })}
                  </Select>
                  {/*)}*/}

                  {/*{isApparel && (*/}
                  {/*  <SoftTypography*/}
                  {/*    variant="button"*/}
                  {/*    fontWeight="bold"*/}
                  {/*    color="text"*/}
                  {/*  >*/}
                  {/*    {productData.status_display}*/}
                  {/*  </SoftTypography>*/}
                  {/*)}*/}
                </React.Fragment>
              }
            />

            {isApparel && (
              <DescriptionItem
                spacing={"normal"}
                keyJsx={
                  <SoftTypography
                    mb={1}
                    display="block"
                    variant="button"
                    fontWeight="medium"
                  >
                    Sync with Dreamship
                  </SoftTypography>
                }
                valueJsx={
                  <TextButton
                    loading={syncing}
                    onClick={() => {
                      setSyncing(true)
                      Axios.post(
                        `${dataUrl}${productData.id}/action_sync_with_dreamship/`,
                        navigate
                      )
                        .then(({ data }) => {
                          setSyncing(false)
                          setProductData(data)
                          dispatch(
                            updateToastData({
                              message: "Synced with Dreamship",
                              severity: TOAST_SEVERITY_SUCCESS,
                            })
                          )
                        })
                        .catch((e) => {
                          setSyncing(false)
                          dispatch(
                            updateToastData({
                              message: e,
                              severity: TOAST_SEVERITY_ERROR,
                            })
                          )
                        })
                    }}
                  >
                    Sync
                  </TextButton>
                }
              />
            )}

            <DescriptionItem
              keyJsx={
                <SoftTypography
                  display="block"
                  variant="button"
                  fontWeight="medium"
                  mr={2}
                >
                  {!isApparel ? "Price" : "Display Price"}
                </SoftTypography>
              }
              valueJsx={
                <React.Fragment>
                  {!isApparel && (
                    <TextField
                      disabled={loading}
                      margin="normal"
                      required
                      value={price}
                      type={"number"}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                      onBlur={() => {
                        setLoading(true)
                        Axios.patch(
                          `${dataUrl}${productData.id}/`,
                          { price },
                          navigate
                        )
                          .then(({ data }) => {
                            setLoading(false)
                            setPrice(data.price)
                            setProductData(data)
                            dispatch(
                              updateToastData({
                                message: "Price Saved",
                                severity: TOAST_SEVERITY_SUCCESS,
                              })
                            )
                          })
                          .catch((e) => {
                            setLoading(false)
                            dispatch(
                              updateToastData({
                                message: e,
                                severity: TOAST_SEVERITY_ERROR,
                              })
                            )
                          })
                      }}
                      onChange={(event) => {
                        setPrice(event.target.value)
                      }}
                    />
                  )}
                  {isApparel && (
                    <SoftTypography
                      variant="button"
                      fontWeight="bold"
                      color="text"
                    >
                      ${productData.display_price}
                    </SoftTypography>
                  )}
                </React.Fragment>
              }
            />
            {!isApparel && (
              <DescriptionItem
                keyJsx={
                  <SoftTypography
                    display="block"
                    variant="button"
                    fontWeight="medium"
                    mr={2}
                  >
                    Preset
                  </SoftTypography>
                }
                valueJsx={
                  <AutocompleteTextField
                    label={"Shop ID"}
                    autocompleteValue={preset}
                    setAutocompleteValue={setPreset}
                    autocompleteUrl={"/api/staff-preset-list/"}
                    params={"&channel=custom"}
                    defaultValue={productData.preset_name}
                    onSelect={(option) => {
                      let data = {
                        preset: option?.value || null,
                      }

                      Axios.patch(
                        `/api/staff-product-detail/${productData.id}/`,
                        data
                      )
                        .then(({ data }) => {
                          productData.preset = data.preset
                        })
                        .catch((e) => {
                          dispatch(
                            updateToastData({
                              message: "Unable to save preset",
                              severity: TOAST_SEVERITY_ERROR,
                            })
                          )
                        })
                    }}
                  />
                }
              />
            )}
          </SoftBox>
        </SoftBox>
      </Card>
    </Grid>
  )
}

export default ProductInfo
